import { compose, defaultProps, withProps } from 'core/libs/recompose';

import bindPropsHOC from 'core/components/bindProps';

import Ad from 'core/components/Ad';
import Adfox from 'core/components/Ad/Adfox';
import AdBillboard from 'core/components/Ad/Billboard';

import sponsoredStyles from 'core/components/Ad/styles/sponsoredStyles';

import mainNative1Style from './styles/MainNative1';
import mainNative2Style from './styles/MainNative2';
import cardReadMore from './styles/CardReadMore';
import nativeAsCard4Style from './styles/NativeAsCard4';

export const Billboard = bindPropsHOC({
  name: 'Billboard',
  height: 250,
  blockId: '433127980',
  options: {
    p1: 'bmewr',
    p2: 'y',
    pct: 'a',
  },
})(AdBillboard);

export const Fullscreen = bindPropsHOC({
  name: 'Fullscreen',
  blockId: '433128188',
  options: {
    p1: 'bsaib',
    p2: 'emiu',
    pct: 'a',
  },
})(Ad);

export const VerticalFirst = bindPropsHOC({
  name: '240x400',
  width: 300,
  height: 600,
  stickyTemp: 3,
  blockId: '433127972',
  options: {
    p1: 'bmewp',
    p2: 'emhk',
    pct: 'a',
  },
})(Ad);

export const VerticalSecond = bindPropsHOC({
  name: '240x400_2nd',
  width: 300,
  height: 600,
  lazy: true,
  blockId: '435627612',
  options: {
    p1: 'brzkb',
    p2: 'ewqs',
    pct: 'a',
  },
})(Ad);

export const Parallax = bindPropsHOC({
  name: 'Parallax',
  width: 928,
  height: 250,
  lazy: true,
  blockId: '446857986',
  options: {
    p1: 'bwuqp',
    p2: 'fhoe',
    pct: 'a',
  },
})(Ad);

export const SuperFooter = bindPropsHOC({
  name: '100%x240',
  width: 600,
  height: 240,
  lazy: true,
  blockId: '433127452',
  options: {
    p1: 'brzkd',
    p2: 'fcuz',
    pct: 'a',
  },
})(Ad);

export const Vitrina1 = bindPropsHOC({
  name: 'vitrina1',
  lazy: true,
  blockId: '474427780',
  options: {
    p1: 'byojd',
    p2: 'exjw',
    pct: 'b',
  },
})(Ad);

export const Vitrina2 = bindPropsHOC({
  name: 'vitrina2',
  lazy: true,
  blockId: '474427810',
  options: {
    p1: 'byojh',
    p2: 'exjw',
    pct: 'b',
  },
})(Ad);

export const Vitrina3 = bindPropsHOC({
  name: 'vitrina3',
  lazy: true,
  blockId: '474427866',
  options: {
    p1: 'byoji',
    p2: 'exjw',
    pct: 'b',
  },
})(Ad);

export const Vitrina4 = bindPropsHOC({
  name: 'vitrina4',
  lazy: true,
  blockId: '474427902',
  options: {
    p1: 'byojj',
    p2: 'exjw',
    pct: 'b',
  },
})(Ad);

// см. также site/components/Ads/Sponsored
export const Sponsored = compose(
  bindPropsHOC({
    name: 'Sponsored',
    blockId: '446858516',
    options: {
      p1: 'bwuqq',
      p2: 'fomx',
      pct: 'a',
    },
  }),
  sponsoredStyles,
)(Ad);

export const InRead = bindPropsHOC({
  name: 'inRead',
  width: 600,
  height: 300,
  blockId: '433128196',
  options: {
    p1: 'brzkf',
    p2: 'fcvb',
    pct: 'a',
  },
})(Ad);

export const AdCenter = bindPropsHOC({
  name: 'Center',
  width: 600,
  height: 240,
  blockId: '446857984',
  lazy: true,
  options: {
    p1: 'bwuqo',
    p2: 'fomw',
    pct: 'a',
  },
})(Ad);

export const Ad240x200 = bindPropsHOC({
  name: '240x200',
  width: 300,
  lazy: true,
  blockId: '433330508',
  options: {
    p1: 'buxcu',
    p2: 'ewzd',
    pct: 'c',
  },
})(Ad);

export const Context = compose(
  bindPropsHOC({
    name: 'Context',
    height: 80,
    lazy: true,
    blockId: '434150560',
    options: {
      p1: 'btrat',
      p2: 'ewzc',
      pct: 'a',
    },
  }),
  defaultProps({ count: 1 }),
  withProps(({ count }) => ({ manualPuids: { puid44: `context_item${count}` } })),
)(Ad);

export const Native1AsCard1 = compose(
  bindPropsHOC({
    name: 'Native1',
    blockId: '435349752',
    options: {
      p1: 'bunwo',
      p2: 'fhzr',
      pct: 'a',
    },
  }),
  mainNative1Style,
)(Ad);

export const Native1AsCard4 = compose(
  bindPropsHOC({
    name: 'Native1',
    blockId: '435349752',
    options: {
      p1: 'bunwo',
      p2: 'fhzr',
      pct: 'a',
    },
  }),
  nativeAsCard4Style,
)(Ad);

export const Native2AsCard3Type0 = compose(
  bindPropsHOC({
    name: 'Native2',
    blockId: '435349762',
    lazy: true,
    options: {
      p1: 'bunwp',
      p2: 'fhzs',
      pct: 'a',
    },
  }),
  mainNative2Style,
)(Ad);

export const Native2AsCard4 = compose(
  bindPropsHOC({
    name: 'Native2',
    blockId: '435349762',
    lazy: true,
    options: {
      p1: 'bunwp',
      p2: 'fhzs',
      pct: 'a',
    },
  }),
  nativeAsCard4Style,
)(Ad);

export const Native3 = compose(
  bindPropsHOC({
    name: 'Native3',
    blockId: '435349782',
    lazy: true,
    options: {
      p1: 'bvqmb',
      p2: 'fjgk',
      pct: 'a',
    },
  }),
  cardReadMore,
)(Ad);

export const NativeFooter = compose(
  bindPropsHOC({
    name: 'NativeFooter',
    lazy: true,
    blockId: '441574994',
    options: {
      p1: 'bunwq',
      p2: 'fhzt',
      pct: 'd',
    },
  }),
  nativeAsCard4Style,
)(Ad);

export const InGallery = bindPropsHOC({
  name: 'InGallery',
  blockId: '480359247',
  disableAutoInit: true,
})(Ad);

export const PopUp = bindPropsHOC({
  name: 'PopUp',
  blockId: '579502875',
  options: {
    pct: 'a',
    pli: 'a',
    plp: 'a',
    pop: 'a',
  },
})(Ad);

export const ShowUp = bindPropsHOC({
  name: 'Show-up',
  blockId: '579502901',
})(Ad);

export const TestAd = bindPropsHOC({
  name: 'TestAd',
  ownerId: 171817,
  params: {
    p1: 'dfsao',
    p2: 'frfe',
  },
})(Adfox);
